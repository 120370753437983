.paymentMain {
  height: 100vh;
}

.paymentMain a {
  text-decoration: none;
}

#btnGoogle div[aria-labelledby="button-label"] {
  width: 100% !important;
  max-width: 100% !important;
  border-radius: 8px !important;
  height: 44px !important;
}

#btnGoogle div[aria-labelledby="button-label"] span {
  font-weight: 600 !important;
  font-size: 16px !important;
}

.border-1 {
  border-width: 1px !important;
}

.paymentMain .MuiGrid-container,
.paymentMain .MuiGrid-item,
.paymentMain .sigupLeftPanel,
.paymentMain .sigupRightPanel {
  height: 100%;
}

.paymentMain .sigupLeftPanel {
  color: #eda044;
  background: #0f0f0f;
  overflow-y: auto;
}

.paymentMain .sigupRightPanel {
  color: rgba(255, 255, 255, 0.38);
  background: #121212;
  overflow-y: auto;
}

.paymentMain .sigupRightPanel .paymentWrap {
  padding: 90px 150px;
}

.StripeElement.StripeElement--focus {
  border: 4px solid rgba(237, 160, 68, 0.87) !important;
}

.payField .MuiFormControl-root .MuiInputBase-root.Mui-focused {
  outline: 4px solid rgba(237, 160, 68, 0.87) !important;
}

.StripeElement.StripeElement--invalid,
.payField .MuiFormControl-root.error-input .MuiInputBase-root {
  border: 1px solid #d92d20 !important;
  outline: none !important;
}

/* payment detail */
.payDetailMain {
  padding: 75px 120px 30px 80px;
}

.payDetailMain h6 {
  color: rgba(255, 255, 255, 0.38);
  font-weight: 400;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  margin: 0 4px 0 12px;
}

.payDetailMain a.login {
  color: #eda044;
  font-weight: 600;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

.payDetailMain h5 {
  color: rgba(255, 255, 255, 0.38);
  font-size: 16px;
}

.payDetailMain h1 {
  font-weight: 300;
  font-size: 36px;
  letter-spacing: -0.02em;
}

.payDetailMain .premiumPlan .premiumHeader {
  background: rgba(237, 160, 68, 0.6);
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.12);
  border-radius: 12px 12px 0px 0px;
  color: #0f0f0f;
  padding: 20px;
}

.payDetailMain .premiumPlan .premiumHeader img {
  margin-right: 14px;
}

.payDetailMain .premiumPlan .premiumHeader h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.payDetailMain .premiumPlan .premiumHeader h3 {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.015em;
}

.payDetailMain .premiumPlan .premiumDetail {
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 0px 0px 12px 12px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #0f0f0f;
  color: rgba(255, 255, 255, 0.87);
  padding: 0 20px 20px 20px;
}

.payDetailMain .premiumPlan .premiumDetail p {
  font-size: 14px;
  line-height: 20px;
}

.payDetailMain .premiumPlan .premiumDetail p span,
h4.referTitle {
  font-weight: 700;
}

.payDetailMain .premiumPlan .premiumDetail .MuiChip-root {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #0f0f0f;
  height: 22px;
}

.payDetailMain .premiumPlan .premiumDetail .MuiChip-root span {
  color: #eda044;
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  padding: 0 8px;
}

.payDetailMain .premiumPlan .premiumDetail .Mui-checked + .MuiSwitch-track {
  background-color: rgba(237, 160, 68, 0.85) !important;
}

.payDetailMain .totalSect p {
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.87);
}

.payDetailMain .totalSect .MuiBox-root {
  padding: 12px 0;
}

.payDetailMain .totalSect .MuiBox-root.totalDue {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.payDetailMain .totalSect .MuiBox-root.totalDue p {
  font-size: 16px;
}

.paymentMain .poweredWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0a0a0a;
  z-index: 9;
  max-width: 50%;
}

.paymentMain .poweredBy {
  padding-left: 96px;
}

.paymentMain .poweredBy p,
.paymentMain .poweredBy a {
  color: rgba(255, 255, 255, 0.38);
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.015em;
}

.paymentMain .poweredBy a {
  margin-left: 30px;
}

.paymentMain .poweredBy img {
  padding: 0 30px 0 6px;
  border-right: 1px solid rgba(255, 255, 255, 0.38);
}

/* payment tab */
.tabSect .flowTitle .MuiTypography-root,
button.btnGPay,
h6.fieldLabel,
h4.referTitle,
.manuallyMain h3 {
  font-family: "Inter", sans-serif;
}

.tabSect .flowTitle {
  cursor: pointer;
}

.tabSect .flowTitle h5 {
  font-weight: 600;
  font-size: 14px;
}

.tabSect .flowTitle p {
  font-size: 14px;
}

.tabSect .flowTitle .bgBorder {
  background-color: rgba(255, 255, 255, 0.38);
  height: 4px;
}

.tabSect .flowTitle.activeFlow .bgBorder {
  background-color: #eda044;
}

.tabSect .flowTitle.activeFlow {
  color: #eda044;
}

/* payment manually */
button.btnGPay {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background: #000000;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 8px;
}

button.btnGPay img {
  margin-right: 6px;
}

.manuallyMain .manuallyTitle p {
  color: rgba(255, 255, 255, 0.38);
  position: relative;
}

.manuallyMain .manuallyTitle p::before,
.manuallyMain .manuallyTitle p::after {
  content: "";
  position: absolute;
  left: 0;
  top: 12px;
  width: 35%;
  height: 1px;
  background: rgba(255, 255, 255, 0.12);
}

.manuallyMain .manuallyTitle p::after {
  left: initial;
  right: 0;
}

h6.fieldLabel {
  color: rgba(255, 255, 255, 0.87);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 6px;
}

.payField .MuiFormControl-root .MuiInputBase-root {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #0f0f0f;
  color: rgba(255, 255, 255, 0.38);
  font-weight: 400;
  font-size: 16px;
  font-family: "Inter", sans-serif;
}

.payField .MuiFormControl-root .MuiInputBase-root input {
  padding: 10px 14px;
  text-overflow: ellipsis;
}

.payField .MuiFormControl-root .MuiInputBase-root input.Mui-disabled {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.38);
}

.payField .MuiInputBase-root input::placeholder {
  opacity: 1;
}

.payField .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.checkControl .checkboxMain {
  margin-top: 8px;
}

.checkControl .checkboxMain .MuiFormControlLabel-label {
  font-weight: 400;
}

.manuallyMain .MuiAutocomplete-root input {
  padding: 1px 5px !important;
}

.manuallyMain .MuiAutocomplete-root .MuiFormLabel-root {
  color: rgba(255, 255, 255, 0.38);
  transform: translate(14px, 11px) scale(1);
}

.manuallyMain .MuiAutocomplete-root .MuiFormLabel-root.MuiInputLabel-shrink,
.manuallyMain .MuiAutocomplete-root .MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator {
  display: none;
}

.manuallyMain .MuiAutocomplete-root .MuiAutocomplete-endAdornment svg {
  fill: rgba(255, 255, 255, 0.38);
}

.manuallyMain .MuiAutocomplete-root .MuiInputBase-root {
  border-bottom: 0;
  border-radius: 8px 8px 0 0;
}

.manuallyMain .MuiFormControl-root.zipCode .MuiInputBase-root {
  border-radius: 0 0 8px 8px;
}

button.btnGPay.btnPaySignUp {
  color: #0f0f0f;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  background: #eda044;
  border: 1px solid #eda044;
  padding: 7px 8px;
  margin: 24px 0 8px 0;
}

h4.referTitle,
.manuallyMain h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

/* stripe field */
#payment-form .StripeElement {
  border-radius: 8px 8px 0 0;
  padding: 12px 14px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #0f0f0f;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
}

#payment-form .expiryField .StripeElement {
  border-radius: 0 0 0 8px;
  border-top: 0;
}

#payment-form .cvcField .StripeElement {
  border-radius: 0 0 8px 0;
  border-top: 0;
}

#payment-form .MuiGrid-container {
  width: 100%;
  margin: 0;
}

#payment-form .email-error,
#payment-form .cardError {
  color: #eb1c26;
  font-size: 14px;
}

#payment-form .email-success {
  color: green;
  font-size: 14px;
}

/* payment signup flow */

.paySignUpMain .referrerField .MuiFormControl-root {
  position: relative;
}

.paySignUpMain .referrerField img {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.MuiTooltip-tooltip {
  padding: 12px !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #0f0f0f !important;
}

.MuiTooltip-tooltip .MuiTooltip-arrow {
  color: #353535 !important;
}

.MuiTooltip-tooltip .refTooltip h2,
.MuiTooltip-tooltip .refTooltip p {
  font-size: 12px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.MuiTooltip-tooltip .refTooltip p {
  font-weight: 500;
  margin-top: 6px;
}

/* signup detail */
.signupDetailMain,
.signupCompleteMain.flex {
  display: flex;
}

.signupDetailMain,
.signupCompleteMain {
  height: 100vh;
  padding: 60px 59px 20px 59px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.side.signupCompleteMain {
  gap: 55px;
  display: flex;
  align-items: start;
}

.side.signupCompleteMain {
  gap: 80px;
}

.signupDetailMain h2 {
  color: rgba(255, 255, 255, 0.38);
  font-weight: 400;
  line-height: 150%;
  font-size: 36px;
}

.signupDetailMain h3,
.signupDetailMain h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.02em;
}

.signupDetailMain h4 {
  font-weight: 300;
}

/* Signup Complete Detail */
.signupCompleteMain p {
  /* color: rgba(255, 255, 255, 0.38); */
  color: #ffffff99;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
}

.signupCompleteMain h3,
.signupCompleteMain h4,
.signupCompleteMain h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.02em;
}

.signupCompleteMain h2 {
  font-weight: 300;
  font-size: 20px;
}

.signupCompleteMain h4 {
  font-weight: 300;
}

.signupCompleteMain button {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background: #0f0f0f;
  border: 1px solid #eda044;
  color: #eda044;
  font-weight: 600;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  text-transform: initial;
  padding: 7px 42px;
}

.signupCompleteMain button img {
  margin-right: 14px;
}

/* signup complete */
.payCompleteMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  color: rgba(255, 255, 255, 0.87);
}

.payCompleteMain .completeImage {
  width: 56px;
  height: 56px;
}

.payCompleteMain h5 {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
}

.payCompleteMain h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
}

.payCompleteMain h6 a {
  color: #eda044;
  font-weight: 700;
}

.payCompleteMain a {
  width: 100%;
}

.payCompleteMain button {
  background: rgba(88, 101, 242, 0.87);
  border: 1px solid rgba(88, 101, 242, 0.87);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  text-transform: initial;
  padding: 7px 8px;
  margin-bottom: 16px;
}

.payCompleteMain button img:first-of-type {
  margin-right: 12px;
}

.payCompleteMain button img:last-of-type {
  margin-left: 12px;
}

.payCompleteMain button.btnDiscord {
  color: #fff;
  background: rgba(88, 101, 242, 0.87);
  border: 1px solid rgba(88, 101, 242, 0.87);
}

.payCompleteMain button.btnShare {
  color: #0f0f0f;
  background: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(255, 255, 255, 0.87);
}

.payCompleteMain button.btnDashboard {
  color: #0f0f0f;
  background: rgba(237, 160, 68, 1);
  border: 1px solid rgba(237, 160, 68, 1);
}

@media (max-width: 1439px) {
  .paymentMain .sigupRightPanel .paymentWrap {
    padding: 90px 50px;
  }

  .payDetailMain,
  .signupDetailMain,
  .signupCompleteMain {
    padding: 75px 50px;
  }

  .paymentMain .poweredBy {
    padding-left: 66px;
  }
}

@media (max-width: 1199px) {
  .paymentMain .sigupRightPanel .paymentWrap {
    padding: 90px 30px;
  }

  .payDetailMain,
  .signupDetailMain,
  .signupCompleteMain {
    padding: 30px 30px 0px 30px;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
  }

  .manuallyMain .manuallyTitle p::before,
  .manuallyMain .manuallyTitle p::after {
    width: 33%;
  }

  .paymentMain .poweredBy a {
    margin-left: 24px;
  }

  .paymentMain .poweredBy img {
    padding: 0 24px 0 6px;
  }

  .payDetailMain .premiumPlan .premiumDetail p {
    font-size: 13px;
  }

  .payDetailMain .premiumPlan .premiumDetail {
    padding: 0 12px 12px 12px;
  }

  .payDetailMain .premiumPlan .premiumHeader {
    padding: 12px;
  }

  .paymentMain .poweredBy {
    padding-left: 46px;
  }
}

@media (max-width: 899px) {
  .paymentMain .sigupRightPanel .paymentWrap {
    padding: 60px 50px;
  }

  .payDetailMain,
  .signupDetailMain,
  .signupCompleteMain {
    padding: 75px 50px 50px 50px;
  }

  .paymentMain .sigupLeftPanel,
  .paymentMain .sigupRightPanel {
    overflow-y: initial;
    padding: 39px 20px;
  }
  /* 
    signup.paymentMain .sigupRightPanel {
        padding:16px 15px;
    } */

  .clip.paymentMain {
    overflow-y: clip;
  }

  .paymentMain {
    overflow-y: auto;
  }

  .paymentMain .MuiGrid-item {
    height: initial;
  }

  .paymentMain .poweredWrap {
    max-width: 100%;
    position: initial;
    background-color: transparent;
  }
}

@media (max-width: 599px) {
  .paymentMain .sigupRightPanel .paymentWrap {
    padding: 60px 30px;
  }

  .container {
    padding: 20px;
  }

  .payDetailMain,
  .signupDetailMain,
  .signupCompleteMain {
    padding: 75px 30px 50px 30px;
  }

  #payment-form .expiryField .StripeElement {
    border-radius: 0;
  }

  #payment-form .cvcField .StripeElement {
    border-radius: 0 0 8px 8px;
  }

  .paymentMain .poweredBy {
    padding-left: 30px;
  }
}

@media (max-width: 474px) {
  .paymentMain .sigupRightPanel .paymentWrap {
    padding: 60px 16px;
  }

  .payDetailMain,
  .signupDetailMain,
  .signupCompleteMain {
    padding: 75px 16px 50px 16px;
  }

  .payDetailMain .premiumPlan .premiumDetail .premiumWrap {
    display: block;
  }

  .payDetailMain .premiumPlan .premiumDetail .premiumWrap p.plansAmt {
    margin: 8px 0 0 52px;
  }

  .signupCompleteMain button {
    padding: 7px 16px;
    font-size: 16px;
  }

  .signupCompleteMain h3,
  .signupCompleteMain h4,
  .signupCompleteMain h2,
  .signupDetailMain h3,
  .signupDetailMain h4,
  .signupDetailMain h2 {
    font-size: 26px;
  }

  .paymentMain .poweredBy {
    padding-left: 16px;
  }

  .paymentMain .poweredBy a {
    margin-left: 20px;
  }
}

@media (max-width: 374px) {
  .payDetailMain h6,
  .payDetailMain a.login,
  .tabSect .flowTitle p,
  .manuallyMain .manuallyTitle p {
    font-size: 12px;
  }

  .manuallyMain .manuallyTitle p::before,
  .manuallyMain .manuallyTitle p::after {
    top: 8px;
  }

  .signupCompleteMain button,
  .payCompleteMain button {
    font-size: 14px;
  }

  .paymentMain .poweredBy {
    display: block;
  }

  .paymentMain .poweredBy a {
    margin: 0 20px 0 0;
  }
}
