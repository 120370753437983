/* .loginMain {
    width: 360px;
    margin: 96px auto;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
}
.loginMain .MuiTypography-root,.loginMain .MuiFormLabel-root,.loginMain .MuiButtonBase-root {
    font-family: "Inter", sans-serif;
}
.loginMain .logoIcon {
    width: 48px;
    height: 48px;
}
.loginMain h5 {
    color: rgba(255, 255, 255, 0.87);
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 12px;
}
.loginMain h6{
    font-weight: 500;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: 4px;
}
.loginMain .MuiFormControl-root .MuiInputBase-root{
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #0F0F0F;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
    font-size: 16px;
    font-family: "Inter", sans-serif;
}
.loginMain .MuiFormControl-root .MuiInputBase-root.Mui-focused{
    outline: 4px solid rgba(237, 160, 68, 0.87) !important;
    border: 0 !important;
}
.loginMain .MuiFormControl-root .MuiInputBase-root input{
    padding: 11px 14px;
    text-overflow: ellipsis;
}
.loginMain .MuiInputBase-root input::placeholder{
    opacity: 1;
}
.loginMain .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
    border: 0;
}
.loginMain .rememberSect svg.MuiSvgIcon-root{
    fill: rgba(255, 255, 255, 0.87);
    font-size: 22px;
}
.loginMain .rememberSect .Mui-checked svg.MuiSvgIcon-root{
    fill: #EDA044;
}
.loginMain .rememberSect span.MuiTypography-root{
    font-weight: 500;
    font-size: 14px;
}
.loginMain a{
    font-weight: 600;
    font-size: 14px;
    color: #EDA044;
    text-decoration: none;
}
.loginMain button{
    color: #0F0F0F;
    font-weight: 600;
    font-size: 16px;
    text-transform: initial;
    padding: 7px 8px;
    width: 100%;
    margin-bottom: 16px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background: #EDA044;
    border: 1px solid #EDA044;
}
.loginMain button:hover{
    background: #EDA044; 
}
.loginMain button.btnGoogle{
    border: 1px solid #D0D5DD;
    background: rgba(255, 255, 255, 0.87);
    padding: 0;
}
.loginMain button.btnGoogle a{
    color: #0F0F0F;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 7px 8px;
}
.loginMain button.btnGoogle img{
    margin-right: 12px;
}
.loginMain h4{
    font-weight: 400;
    font-size: 14px;
}
.loginMain h3,.loginMain h2{
    color: rgba(255, 255, 255, 0.38);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.loginMain h3 span{
    font-weight: 700;
}
.loginMain h3 a{
    font-size: 16px;
}
.loginMain h2{
    font-style: italic;
}
@media(max-width: 424px){
    .loginMain{
        width: 90%;
        margin: 50px auto;
    }
}

 */

.loginMain {
  /* color: rgba(255, 255, 255, 0.6); */
  color: #ffffff61;
  text-align: center;
}

.signup.loginMain {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.loginMain .MuiTypography-root,
.loginMain .MuiFormLabel-root,
.loginMain .MuiButtonBase-root {
  font-family: "Inter", sans-serif;
}
.loginMain .logoIcon {
  width: 48px;
  height: 48px;
}
.loginMain h5 {
  color: rgba(255, 255, 255, 0.87);
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 12px;
}
.loginMain h6 {
  font-weight: 700;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 4px;
}
.loginMain .MuiFormControl-root .MuiInputBase-root {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 0.1px solid rgba(255, 255, 255, 0.12);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #0f0f0f;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  height: 44px;
}
.loginMain .MuiFormControl-root .MuiInputBase-root.Mui-focused {
  box-shadow: 0 0 0 4px rgba(237, 160, 68, 0.87);
}
.loginMain .MuiFormControl-root .MuiInputBase-root input {
  padding: 11px 14px;
  text-overflow: ellipsis;
  height: 44px;
}
.loginMain .MuiInputBase-root input::placeholder {
  opacity: 1;
}
.loginMain .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 0;
}
.loginMain .rememberSect svg.MuiSvgIcon-root {
  fill: rgba(255, 255, 255, 0.87);
  font-size: 22px;
}
.loginMain .rememberSect .Mui-checked svg.MuiSvgIcon-root {
  fill: #eda044;
}
.loginMain .rememberSect span.MuiTypography-root {
  font-weight: 500;
  font-size: 14px;
}
.loginMain a {
  font-weight: 600;
  font-size: 14px;
  color: #eda044;
  text-decoration: none;
}
.loginMain button {
  color: #0f0f0f;
  font-weight: 600;
  font-size: 16px;
  text-transform: initial;
  padding: 7px 8px;
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background: #eda044;
  border: 1px solid #eda044;
}
.loginMain button:hover {
  background: #eda044;
}
.loginMain button.btnGoogle {
  border: 1px solid #d0d5dd;
  background: rgba(255, 255, 255, 0.87);
  padding: 0;
}
.loginMain button.btnGoogle a {
  color: #0f0f0f;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 7px 8px;
}
.loginMain button.btnGoogle img {
  margin-right: 12px;
}
.loginMain h4 {
  font-weight: 400;
  font-size: 14px;
}
.loginMain h3,
.loginMain h2 {
  color: rgba(255, 255, 255, 0.38);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.loginMain h3 span {
  font-weight: 700;
}
.loginMain h3 a {
  font-size: 16px;
}
.loginMain h2 {
  font-style: italic;
}

@media (max-width: 899px) {
  .loginMain {
    /* width: 90%; */
    margin-bottom: 36px;
  }

  /* .signup.loginMain {
    flex-direction: column;
  } */
}
