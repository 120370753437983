/* @tailwind base;
@tailwind components; */
@tailwind utilities;
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

/* Toastify */
:root {
  --toastify-toast-width: auto;
  --toastify-toast-min-height: 48px;
}

/* Hide default close button */
.Toastify__close-button {
  display: none;
}

.Toastify__toast-body {
  margin-left: 16px;
  margin-right: 32px;
}

.Toastify__toast {
  border-radius: 12px !important;
  font-size: 14px;
  font-family: Inter;
}
.Toastify__toast::after {
  position: absolute;
  right: 20px;
  padding-top: 5px !important;
}
.Toastify__toast::before {
  position: relative;
  z-index: 100000;
  left: 10px;
  top: 6px;
}

.Toastify__toast--success {
  border: 1px solid #039855;
  background: #111a16 !important;
  color: #a6f4c5 !important;
}
.Toastify__toast--success::after {
  content: url("./assets/images/closeSuccess.svg");
}
.Toastify__toast--success::before {
  content: url("./assets/images/toastSuccess.svg");
}

.Toastify__toast--error {
  border: 1px solid #b54708;
  background: #170e0a !important;
  color: #fedf89 !important;
}
.Toastify__toast--error::after {
  content: url("./assets/images/closeWarning.svg");
}
.Toastify__toast--error::before {
  content: url("./assets/images/toastWarning.svg");
}

@media screen and (max-width: 900px) {
  .setting_page_heading{
    padding: 16px 14px;
    text-align: center;
    background-color: #1B1B1B;
    font-size: 16px !important;
  }
  .mb_0{
    margin-bottom: 0px !important;
  }
  .sidebar-drawer .MuiDrawer-paper{
    max-height: 100vh;
  }

  .dashboardMain{
    min-width: 250px;
  }

}


