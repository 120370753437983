.checkboxMain svg.MuiSvgIcon-root{
  fill: rgba(255, 255, 255, 0.87);
  font-size: 22px;
}
.checkboxMain .Mui-checked svg.MuiSvgIcon-root{
  fill: #EDA044;
}
.checkboxMain span.MuiTypography-root{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.checkboxMain label.MuiFormControlLabel-root{
  align-items: flex-start;
}
.checkboxMain .MuiButtonBase-root{
  padding: 0 9px;
}